@import '/src/shared/ads/partners/${AD_PARTNER}/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --page-top: 0px;
        --sidenav-width: 0px;
    }

    @screen desktop {
        :root {
            --sidenav-width: theme('width.16')
        }
    }

    pre {
        text-wrap: wrap;
    }

    main {
        min-height: calc(100vh - theme('height.20') - theme('gap.5'));
    }

    .layout {
        max-width: 1340px;
    }

    [dir="ltr"] .layout {
        margin-left: var(--sidenav-width)
    }

    [dir="rtl"] .layout {
        margin-right: var(--sidenav-width)
    }

    .hyper-loader {
        display: none;
        justify-content: center;
        align-items: center;
    }

    .htmx-request .hyper-loader {
        display: flex;
    }

    .htmx-request .hyper-content {
        display: none;
    }

    @media (hover: none) {
        [data-tooltip-anchor],
        [data-tooltip-target="trigger"] {
            -webkit-touch-callout: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}

@layer utilities {
    .page-top {
        top: var(--page-top)
    }

    .content-top {
        top: calc(var(--page-top) + theme('height.16'))
    }

    .sidenav-width {
        width: var(--sidenav-width)
    }

    .scrollable {
        --scrollbar-track-color: theme('colors.transparent');
        --scrollbar-thumb-color: theme('colors.metasrc-100');
    }

    .scrollable > .pad-scroll {
        width: calc(100% - theme('width.2'));
    }

    .scrollable::-webkit-scrollbar {
        @apply h-2 w-2;
    }

    .scrollable::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
        background-clip: padding-box;
        @apply rounded-lg;
    }

    .scrollable::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
    }

    @supports not selector(::-webkit-scrollbar) {
        .scrollable {
            scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
        }
    }

    .overflow-unset {
        overflow-x: unset;
        overflow-y: unset;
    }

    [placeholder] {
        text-overflow: ellipsis;
    }

    .tierlist-filters {
        grid-template-areas:
                "filters filters toggle"
                "search search search";
        grid-template-columns: repeat(3, 1fr);
    }
    @screen desktop {
        .tierlist-filters {
            grid-template-areas:
                "filters search search toggle";
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @screen full {
        .tierlist-filters {
            grid-template-areas:
                "filters filters search search search toggle";
            grid-template-columns: repeat(6, 1fr);
        }
    }

    * {
        --cancel-color: theme("colors.red.500");
    }

    *::-webkit-search-cancel-button {
        -webkit-appearance: none;
        -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cline x1='10' y1='10' x2='90' y2='90' stroke='black' stroke-width='10'/%3E%3Cline x1='90' y1='10' x2='10' y2='90' stroke='black' stroke-width='10'/%3E%3C/svg%3E");
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cline x1='10' y1='10' x2='90' y2='90' stroke='black' stroke-width='10'/%3E%3Cline x1='90' y1='10' x2='10' y2='90' stroke='black' stroke-width='10'/%3E%3C/svg%3E");
        @apply h-3 w-3 cursor-pointer bg-metasrc-100 hover:bg-META_COLOR;
    }
}