@layer utilities {
    #bgdiv {
        z-index: unset !important;
        width: calc(100% - var(--sidenav-width)) !important;
    }

    #pw-oop-bottom_rail {
        @apply sticky !important;
        @apply bg-black !important;
        @apply bg-opacity-50 !important;
    }

    .fc-button-background {
        @apply bg-metasrc-600 !important;
    }

    .pw_report_ad_container {
        @apply hidden !important;
    }

    @screen desktop {
        [dir="ltr"] #bgdiv {
            left: var(--sidenav-width) !important;
        }

        [dir="rtl"] #bgdiv {
            right: var(--sidenav-width) !important;
        }

        #pw-oop-bottom_rail {
            width: calc(100% - var(--sidenav-width)) !important;
        }

        [dir="ltr"] #pw-oop-bottom_rail {
            margin-left: var(--sidenav-width) !important;
        }

        [dir="rtl"] #pw-oop-bottom_rail {
            margin-right: var(--sidenav-width) !important;
        }
    }

    [dir="rtl"] .pw-corner-ad-video {
        right: unset !important;
        left: 0 !important;
        margin-left: 10px !important;
        margin-right: 0 !important;
    }

    body.flex-skin-active header,
    body.flex-skin-active #sidenav,
    body.flex-leaderboard-active header,
    body.flex-leaderboard-active #sidenav {
        @apply absolute
    }

    body.flex-skin-active nav.layout,
    body.flex-skin-docked nav.layout,
    body.flex-skin-active main.layout,
    body.flex-skin-docked main.layout {
        max-width: 1100px !important;
    }

    @screen tablet {
        body.flex-skin-active .spacer,
        body.flex-skin-docked .spacer {
            @apply w-5 !important;
        }
    }

    body.flex-skin-docked main,
    body.flex-leaderboard-docked main {
        @apply mt-32
    }

    body.flex-skin-docked #skyscraper,
    body.flex-leaderboard-docked #skyscraper {
        top: calc(var(--page-top) + theme("height.20"))
    }
}